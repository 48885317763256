html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow-x: hidden; */
}

body {
  padding: 0;
  margin: 0;
  /* overflow-x: hidden !important; */
  font-family: 'MTS Sans';
  height: 100vh;
  font-size: '17px !important' ;
}

body::-webkit-scrollbar { 
  width: 0;
}

.App {
  position: relative;
  min-width: 360px;
}

.IE {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.min-height-wrapper {
  min-height: 100vh;
}

a[class*="makeStyles-link"]:hover,

div a:hover {
  color: inherit;
  text-decoration: none;
}

a:focus {
  outline: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
