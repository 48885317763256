@font-face {
    font-family: 'MTSSans';
    src: url('MTSSans-Medium.otf');
    src: local('MTSSans-Medium'), local('MTSSans-Medium'),
    url('MTSSans-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MTSSans';
    src: url('MTSSans-Regular.otf');
    src: local('MTSSans-Regular'), local('MTSSans-Regular'),
    url('MTSSans-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MTSSans Bold';
    src: url('MTSSans-Bold.otf');
    src: local('MTSSans-Bold'), local('MTSSans-Bold'),
    url('MTSSans-Bold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'MTSSans Wide';
    src: url('MTSSans-UltraWide.otf');
    src: local('MTSSans-UltraWide'), local('MTSSans-UltraWide'),
    url('MTSSans-UltraWide.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'MTSSans Black';
    src: url('MTSSans-Black.otf');
    src: local('MTSSans-Black'), local('MTSSans-Black'),
    url('MTSSans-Black.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}
